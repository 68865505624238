<template>
  <div class="grid grid-cols-12">
    <div
      v-if="!loading"
      class="p-6 pb-0 col-span-6 md:col-span-8 lg:col-span-8 xl:col-span-9"
    >
      <WaveformVisualizer
        v-if="activeTab === 'all'"
        class="mb-5"
        :user_png="user_PNG"
        :oper_png="oper_PNG"
        :fileHash="fileHash"
        :cue="cue"
        :duration="file.duration.mix"
        :activeTab="activeTab"
        @update:active-tab="(val) => (activeTab = val)"
        scope="all"
        wide="1"
        @changeCue="(val) => (cue = val)"
        :op_url="op_URL"
        :user_url="user_URL"
      />
      <div v-if="activeTab === 'operator'" class="w-full">
        <AudioTranscriber
          :oper_png="oper_PNG"
          :user_png="user_PNG"
          :op_url="op_URL"
          :user_url="user_URL"
          :rule_for_symbols="file.rule_for_symbols"
          scope="oper"
          :fileHash="fileHash"
          :fileId="fileId"
          :duration="file.duration.op"
          :saved-lines="operLines"
          :activeTab="activeTab"
          :lang="file.lang"
          :meta="file"
          @fileMarked="fileTaggedForModel"
          @update:active-tab="(val) => (activeTab = val)"
          @save:changedLines="saveChangedLines"
        />
      </div>
      <div v-if="activeTab === 'client'" class="w-full">
        <AudioTranscriber
          :oper_png="oper_PNG"
          :user_png="user_PNG"
          :rule_for_symbols="file.rule_for_symbols"
          scope="user"
          :user_url="user_URL"
          :op_url="op_URL"
          :fileHash="fileHash"
          :fileId="fileId"
          :duration="file.duration.user"
          :saved-lines="userLines"
          :activeTab="activeTab"
          :lang="file.lang"
          :meta="file"
          @fileMarked="fileTaggedForModel"
          @update:active-tab="(val) => (activeTab = val)"
        />
      </div>
    </div>
    <header
      class="border-l col-span-6 md:col-span-4 lg:col-span-4 xl:col-span-3"
      style="height: calc(100vh - 60px); overflow-y: auto"
    >
      <div class="flex flex-col h-full bg-gray-50">
        <div class="p-4 border-b bg-white">
          <h1 class="text-xl font-semibold">
            <div
              class="flex items-center leading-none"
              :class="{
                'text-yellow-500': file && file.status === 1,
                'text-blue-500': file && file.status === 2,
                'text-green-500': file && file.status === 3,
                'text-indigo-500': file && file.status === 4,
              }"
            >
              {{ stateTitle }}
              <i v-if="loading" class="el-icon-loading"></i>
            </div>
          </h1>
          <div class="mt-1 text-xs font-light text-gray-700">
            ID: {{ fileId }}
          </div>
        </div>
        <div class="p-4 h-full overflow-y-auto">
          <ul class="flex flex-col" v-if="file && file.comments">
            <li
              v-for="comment in file.comments"
              :key="comment.datetime"
              class="mb-3 font-light text-xs text-gray-600 bg-white border rounded-lg p-2 px-3 inline-block w-4/5"
              :class="{
                'self-end rounded-tr-none':
                  user && user._id === comment.author._id,
                'rounded-tl-none': user && user._id !== comment.author._id,
              }"
            >
              <div style="font-size: 13px" class="font-semibold mb-0.5">
                {{ comment.author.first_name }} {{ comment.author.last_name }}
              </div>
              <div class="mb-0.5 flex items-center">
                <span
                  :class="{
                    'text-yellow-500': comment.status.before === 1,
                    'text-blue-500': comment.status.before === 2,
                    'text-green-500': comment.status.before === 3,
                    'text-indigo-500': comment.status.before === 4,
                  }"
                  >{{ getStatusTitle(comment.status.before) }}</span
                >
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24px"
                    height="12px"
                    viewBox="0 0 24 24"
                    fill="black"
                  >
                    <path
                      d="M4 12H20M20 12L14 6M20 12L14 18"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <span
                  :class="{
                    'text-yellow-500': comment.status.after === 1,
                    'text-blue-500': comment.status.after === 2,
                    'text-green-500': comment.status.after === 3,
                    'text-indigo-500': comment.status.after === 4,
                  }"
                  >{{ getStatusTitle(comment.status.after) }}</span
                >
              </div>
              <div>{{ comment.comment }}</div>
              <div style="font-size: 11px" class="text-gray-400 text-right">
                {{ dateFormat(comment.datetime, "dd MMM HH:mm") }}
              </div>
            </li>
          </ul>
        </div>
        <div class="mt-auto">
          <ul class="text-xs mb-1.5 font-light px-2">
            <li
              v-for="t in tips"
              :key="t"
              @click="comment = t"
              class="border inline-block mr-1 rounded px-1.5 py-0.5 text-gray-500 cursor-pointer hover:bg-gray-50 bg-white"
            >
              {{ t }}
            </li>
          </ul>
          <div class="bg-white border-t pt-2">
            <textarea
              v-model="comment"
              type="textarea"
              placeholder="Примечание"
              name=""
              class="w-full p-2.5 pt-1 font-light text-xs"
              cols="30"
              rows="2"
            ></textarea>

            <div class="flex items-center justify-end mb-7 mt-2 mr-2">
              <el-button
                v-for="(button, index) in availableButtons"
                :key="index"
                @click="changeStatus(button.status.to)"
                :type="button.type"
                plain
                :disabled="changeStatusLoading"
              >
                {{ button.title }}
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <el-dialog v-model="showRatingDialog" title="Оцените качество">
      <el-rate :size="80" v-model="rating"></el-rate>
    </el-dialog>
  </div>
</template>

<script>
import AudioTranscriber from "./shared/components/AudioTranscriber";
import WaveformVisualizer from "./shared/components/WaveformVisualizer";
import { getTranscript, changeStatus, getFile } from "@/api/files";
import { getStatus } from "./shared/statuses";
import { format } from "date-fns";

export default {
  components: {
    AudioTranscriber,
    WaveformVisualizer,
  },
  props: {
    state: {
      type: [String],
      required: true,
    },
    fileId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      cue: 0,
      loading: true,
      changeStatusLoading: false,
      lines: null,
      showRatingDialog: false,
      rating: null,
      controlButtons: [
        {
          title: "В работу",
          status: {
            from: 0,
            to: 1,
          },
          scopes: ["verifier", "annotator"],
          type: "primary",
        },
        {
          title: "Размечен",
          status: {
            from: 1,
            to: 2,
          },
          scopes: ["annotator"],
          type: "primary",
        },
        {
          title: "На верификацию",
          status: {
            from: 2,
            to: 3,
          },
          scopes: ["verifier"],
          type: "primary",
        },
        {
          title: "Верифицировать",
          status: {
            from: 3,
            to: 4,
          },
          scopes: ["verifier", "admin"],
          type: "primary",
        },
        {
          title: "Вернуть на верификацию",
          status: {
            from: 4,
            to: 3,
          },
          scopes: ["verifier", "admin"],
          type: "primary",
        },
        {
          title: "Возврат",
          status: {
            from: 3,
            to: 1,
          },
          scopes: ["verifier"],
          type: "danger",
        },
        {
          title: "Возврат",
          status: {
            from: 1,
            to: 0,
          },
          scopes: ["annotator"],
          type: "danger",
        },
        {
          title: "Возврат в работу",
          status: {
            from: 2,
            to: 1,
          },
          scopes: ["annotator", "verifier"],
          type: "danger",
        },
      ],
      activeTab: "operator",

      file: {},
      comment: null,
      tips: ["Не прошел верификацию", "Исправлено"],
    };
  },
  computed: {
    user() {
      return this.$store.state.user.user;
    },
    oper_PNG() {
      return this.file?.op_url_png;
    },
    user_PNG() {
      return this.file?.user_url_png;
    },
    op_URL() {
      return this.file?.op_url;
    },
    user_URL() {
      return this.file?.user_url;
    },
    fileHash() {
      return this.file?.url_hash;
    },
    operLines() {
      if (this.lines) {
        return this.lines["op"];
      }
      return [];
    },
    userLines() {
      if (this.lines) {
        return this.lines["user"];
      }
      return [];
    },
    userScopes() {
      return this.$store.getters["user/getUserScopes"];
    },
    stateTitle() {
      if (!this.file) return null;
      return this.getStatusTitle(this.file.status);
    },
    availableButtons() {
      if (!this.file) return [];
      return this.controlButtons.filter((cb) => {
        if (cb.status.from === parseInt(this.file.status)) {
          const filteredArray = cb.scopes.filter((value) =>
            this.userScopes.includes(value)
          );
          if (filteredArray.length > 0) {
            return true;
          }
        }
        return false;
      });
    },
  },
  async mounted() {
    this.lines = await getTranscript(this.fileId);
    this.file = await getFile(this.fileId);
    this.loading = false;
    console.log("tempVariable", this.$options.tempVariable);
  },
  methods: {
    fileTaggedForModel(taggedFileForModel) {
      console.log(this.file);
      this.file.is_model = taggedFileForModel;
    },
    async changeStatus(status) {
      this.changeStatusLoading = true;
      const res = await changeStatus(this.fileId, status, this.comment);
      this.changeStatusLoading = false;
      if (res._success) {
        this.$router.push({
          name: "file-view",
          params: { state: status, fileId: this.fileId },
        });
        this.file = await getFile(this.fileId);
      } else {
        this.$message.error(res.message || "Ошибка");
      }
      console.log("change", status);
    },
    getStatusTitle(status) {
      return getStatus(status) && getStatus(status).title;
    },
    dateFormat(date, frmt) {
      return format(new Date(date), frmt);
    },
    saveChangedLines({ type, payload }) {
      console.log("hello");
      if (type === "op") {
        this.lines["op"] = payload;
      }
      if (type === "user") {
        this.lines["user"] = payload;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.el-rate:deep(.el-rate__item) {
  .el-rate-icon {
    font-size: 50px !important;
  }
}
</style>
