<template>
  <div class="flex flex-col">
    <WaveformVisualizer
      ref="wf"
      :user_png="user_png"
      :oper_png="oper_png"
      :op_url="op_url"
      :user_url="user_url"
      :fileHash="fileHash"
      :scope="scope"
      :cue="cue"
      :time-start="timeStart"
      :time-end="timeEnd"
      :activeInterval="activeInterval"
      :duration="duration"
      :lines="lines"
      :activeTab="activeTab"
      @setIntervals="setIntervals"
      @changeCue="(val) => (cue = val)"
      @activeInterval="(val) => (activeInterval = parseInt(val))"
      @set-time="setTime"
      @resetSelection="resetSelection"
      @update:active-tab="(val) => $emit('update:active-tab', val)"
    />

    <div v-if="state !== 0" class="my-6">
      <el-row>
        <el-col :xs="24" :sm="24" :md="12" :lg="8">
          <FillingForm
            v-show="!payedAudio"
            ref="filling-form"
            @add:line="addLine"
            @update:line="updateLine"
            :lang="lang"
            :rule_for_symbols="rule_for_symbols"
          />
          <div
            v-if="
              user.scopes.includes('admin') || user.scopes.includes('verifier')
            "
            class="bg-gray-50 border border-solid border-gray-200 rounded-xl flex px-5 py-3 justify-between mt-5 items-start"
          >
            <div class="flex">
              <div>
                <p class="text-gray-800">Для модели</p>
                <p
                  class="mt-1 mb-0 leading-4 text-gray-500"
                  style="font-size: 11px"
                >
                  Данный файл будет использоваться для эталонной модели
                  распознования речи.
                </p>
              </div>
            </div>
            <el-switch
              @click="changeTagForModel"
              v-model="is_model"
              :inactive-value="0"
              :active-value="1"
            ></el-switch>
          </div>
          <div
            @click="showInform = !showInform"
            class="bg-gray-50 border border-solid border-gray-200 rounded-xl flex px-5 py-2 mt-5 justify-between items-center cursor-pointer"
          >
            <p class="mt-0 text-gray-800">Правила разметки</p>
            <svg
              class="ov-icon"
              aria-hidden="true"
              width="28"
              height="28"
              viewBox="-1.6 -1.6 19.2 19.2"
              fill="rgba(31, 41, 55)"
              style="font-size: 2.88em"
            >
              <path
                d="M8 15A7 7 0 118 1a7 7 0 010 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
              ></path>
              <path
                d="M5.255 5.786a.237.237 0 00.241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 00.25.246h.811a.25.25 0 00.25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
              ></path>
            </svg>
          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="11" :lg="15" :offset="1">
          <ul style="height: calc(100vh - 440px - 3rem); overflow-y: auto">
            <li
              v-for="(line, idx) in lines"
              :key="idx"
              class="flex items-start leading-none mb-5 relative cursor-pointer hover:bg-gray-100"
              :class="[activeInterval === idx ? 'bg-yellow-100' : '']"
              @click="selectLine(line.start, line.end)"
            >
              <div class="mr-2 text-green-600">
                <svg
                  style="width: 14px; height: 14px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <circle
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="rgb(5 150 105)"
                    stroke-width="1.5"
                  />
                  <path
                    d="M12 8V12L14.5 14.5"
                    stroke="rgb(5 150 105)"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <div class="-mt-0.5">
                <div class="flex items-center text-xs font-light">
                  <div class="text-gray-400">
                    <span>{{ timeNormalizer(line.start) }}</span> -
                    <span>{{ timeNormalizer(line.end) }}</span>
                  </div>
                  <span
                    v-if="line.tag"
                    class="bg-blue-400 rounded text-white px-1 ml-2"
                    >{{ defineTag(line.tag) }}</span
                  >
                  <span
                    v-if="line.lang"
                    class="bg-blue-400 rounded text-white px-1 ml-2"
                    >{{ line.lang === "kz" ? "Қаз" : "Рус" }}</span
                  >
                  <span
                    v-if="line.gender"
                    class="bg-blue-400 rounded text-white px-1 ml-2"
                    >{{ defineGender(line.gender) }}</span
                  >
                </div>

                <div class="mt-1">
                  <span class="text-gray-600">{{ line.text }}</span>
                </div>
              </div>

              <div class="flex-shrink-0 ml-auto">
                <button
                  class="text-gray-300 hover:text-gray-700"
                  @click.stop="deleteInterval(idx)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16px"
                    height="16px"
                    viewBox="0 0 24 24"
                    class="text-red-500"
                    fill="none"
                  >
                    <path
                      d="M20.5001 6H3.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M18.8332 8.5L18.3732 15.3991C18.1962 18.054 18.1077 19.3815 17.2427 20.1907C16.3777 21 15.0473 21 12.3865 21H11.6132C8.95235 21 7.62195 21 6.75694 20.1907C5.89194 19.3815 5.80344 18.054 5.62644 15.3991L5.1665 8.5"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M9.5 11L10 16"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M14.5 11L14 16"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M6.5 6C6.55588 6 6.58382 6 6.60915 5.99936C7.43259 5.97849 8.15902 5.45491 8.43922 4.68032C8.44784 4.65649 8.45667 4.62999 8.47434 4.57697L8.57143 4.28571C8.65431 4.03708 8.69575 3.91276 8.75071 3.8072C8.97001 3.38607 9.37574 3.09364 9.84461 3.01877C9.96213 3 10.0932 3 10.3553 3H13.6447C13.9068 3 14.0379 3 14.1554 3.01877C14.6243 3.09364 15.03 3.38607 15.2493 3.8072C15.3043 3.91276 15.3457 4.03708 15.4286 4.28571L15.5257 4.57697C15.5433 4.62992 15.5522 4.65651 15.5608 4.68032C15.841 5.45491 16.5674 5.97849 17.3909 5.99936C17.4162 6 17.4441 6 17.5 6"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </div>
            </li>
          </ul>

          <div
            v-if="lines && lines.length && !payedAudio"
            class="text-right mt-5"
          >
            <el-button type="primary" plain @click="save">Сохранить</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-dialog
      v-model="showInform"
      top="20px"
      width="90%"
      custom-class="rounded-xl"
    >
      <template #title>Правила заполнения</template>
      <el-table :data="rules">
        <el-table-column label="Пример" prop="example"></el-table-column>
        <el-table-column
          label="Правильная разметка"
          prop="correct_markup"
        ></el-table-column>
        <el-table-column label="Примечание" prop="footnote"></el-table-column>
        <el-table-column
          width="80px"
          label="Язык"
          prop="lang"
        ></el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import WaveformVisualizer from "./WaveformVisualizer";
import { saveTranscript, tagForModel } from "@/api/files";
import FillingForm from "@/views/files/shared/components/FillingForm";
import { defineTag } from "@/views/files/shared/tags";
import { getRules } from "@/api/rules";
export default {
  emits: ["update:active-tab"],
  components: {
    WaveformVisualizer,
    FillingForm,
  },

  props: {
    oper_png: {
      type: String,
      required: true,
    },
    user_png: {
      type: String,
      required: true,
    },
    op_url: {
      type: String,
      required: true,
    },
    user_url: {
      type: String,
      required: true,
    },
    fileHash: {
      type: String,
      required: true,
    },
    scope: {
      type: String,
      required: true,
    },
    fileId: {
      type: String,
      required: true,
    },
    meta: {
      type: Object,
    },
    lang: {
      type: String,
      required: true,
    },
    savedLines: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: Number,
      default: 0,
    },
    activeTab: {
      type: String,
      required: true,
    },
    rule_for_symbols: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      cue: 0,
      activeInterval: -1,

      timeStart: 0,
      timeEnd: 0,

      lines: null,
      editingLineIndex: null,
      is_model: 0,
      showInform: false,
      changeModelTypeLoading: false,
      rules: [],
    };
  },

  computed: {
    state() {
      return parseInt(this.$route.params.state);
    },
    user() {
      return this.$store.state.user.user;
    },
    payedAudio() {
      return this.state === 5;
    },
  },
  mounted() {
    this.lines = JSON.parse(JSON.stringify(this.savedLines));
    setTimeout(() => {
      let file = { ...this.meta };
      this.is_model = file.is_model;
    }, 300);
    this.getRules();
  },

  methods: {
    async getRules() {
      let data = await getRules();
      console.log(data);
      this.rules = data.data;
    },
    async changeTagForModel() {
      this.changeModelTypeLoading = true;
      let action = "unmarked";
      if (this.is_model) {
        action = "marked";
      }
      let data = await tagForModel(this.fileId, action);
      if (data._success) {
        this.$emit("fileMarked", data._success);
      }
      console.log(this.is_model);
      this.changeModelTypeLoading = false;
    },
    addLine({ text, tag, lang, gender }) {
      if (this.timeEnd - this.timeStart > 0) {
        let i = 0;
        for (i = 0; i < this.lines.length; i++) {
          if (this.lines[i].start >= this.timeEnd) break;
        }

        this.lines.splice(i, 0, {
          start: this.timeStart,
          end: this.timeEnd,
          text,
          tag,
          lang,
          gender,
        });

        this.$refs["filling-form"].resetFormData();
        this.setIntervals();
      }
      this.resetSelection();
    },

    updateLine({ text, tag, lang, gender, lineIndex }) {
      this.lines[lineIndex] = {
        ...this.lines[lineIndex],
        text,
        tag,
        lang,
        gender,
      };
      this.$refs["filling-form"].resetFormData();
    },

    setTime(payload) {
      if (payload.timeEnd - payload.timeStart > 15) {
        payload.timeEnd = payload.timeStart + 15;
      } else if (payload.timeEnd - payload.timeStart < 0.5) {
        payload.timeEnd = 0;
        payload.timeStart = 0;
      }

      const startTime =
        Math.round(payload.timeStart * 100 + Number.EPSILON) / 100;
      this.timeStart = startTime;

      Math.round(payload.timeStart * 100 + Number.EPSILON) / 100;
      this.timeEnd = Math.round(payload.timeEnd * 100 + Number.EPSILON) / 100;

      if (this.timeEnd - this.timeStart > 2) {
        this.$nextTick(() => {
          this.$refs["filling-form"].resetFormData();
          this.$refs["filling-form"].$refs["transcribe-input"].focus();
        });
      }
    },

    selectLine(start, end) {
      this.timeStart = start;
      this.timeEnd = end;
      this.$refs.wf.setCurrentTime(start);
      this.setIntervals();

      for (let i = 0; i < this.lines.length; i++) {
        if (this.lines[i].start === start && this.lines[i].end === end) {
          this.editIntervalText(i);
          break;
        }
      }
    },

    timeNormalizer(timeInSeconds) {
      var pad = function (num, size) {
          return ("000" + num).slice(size * -1);
        },
        time = parseFloat(timeInSeconds).toFixed(3),
        hours = Math.floor(time / 60 / 60),
        minutes = Math.floor(time / 60) % 60,
        seconds = Math.floor(time - minutes * 60),
        milliseconds = time.slice(-3);
      if (timeInSeconds >= 3600) {
        return (
          pad(hours, 2) +
          ":" +
          pad(minutes, 2) +
          ":" +
          pad(seconds, 2) +
          "," +
          pad(milliseconds, 2)
        );
      }
      return (
        pad(minutes, 2) + ":" + pad(seconds, 2) + "," + pad(milliseconds, 2)
      );
    },

    async save() {
      await saveTranscript(this.fileId, {
        channel: this.scope === "oper" ? "op" : this.scope,
        lines: this.lines,
        user_type: this.user.scopes[0],
      });
      this.$emit("save:changedLines", {
        type: this.scope === "oper" ? "op" : this.scope,
        payload: this.lines,
      });
    },

    defineGender(gender) {
      return gender === "m" ? "Мужчина" : "Женщина";
    },

    defineTag(tag) {
      return defineTag(tag);
    },

    resetSelection() {
      this.timeStart = 0;
      this.timeEnd = 0;
      this.setIntervals();
    },

    setIntervals() {
      console.log("set intervaals");
      this.$refs.wf.setIntervals(this.lines);
    },

    editIntervalText(i) {
      this.editingLineIndex = i;
      this.$refs["filling-form"].insertFormData({
        ...this.lines[i],
        lineIndex: i,
      });
    },

    deleteInterval(i) {
      this.lines.splice(i, 1);
      this.setIntervals();
    },
  },
};
</script>

<style></style>
